/**
 * @ComponentFor LatestMagazineBlockViewModel
 */

import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import ILatestMagazineBlockViewModel from './LatestMagazineBlockViewModel.type';
import Horizontal from './HorizontalView';
import Vertical from './VerticalView';
import {
  small,
  huge,
  delta,
  beta,
  kappa,
  thin,
  black,
  lightBlack,
  darkerGray,
  whitelilac,
  pixelsToUnit,
  minMediumMediaQuery,
  minLargeMediaQuery,
} from 'Shared/Style';
import { Breakpoint, useCurrentBreakpoint, translate } from '@avensia/scope';
import { BOX_ITEM_CLASSNAME } from 'Shared/BoxLayout';
import { Style } from '@glitz/type';
import Link from 'Shared/Link';

type ButtonPropType = {
  linkUrl: string;
};

const LatestMagazineBlock = (props: ILatestMagazineBlockViewModel & StyledProps & ButtonPropType) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const magazineUrl = !!props.linkUrl ? props.linkUrl.url : './';
  const layoutCss: Style = props.isHorizontalCards
    ? {
        marginBottom: pixelsToUnit(40),
        display: 'flex',
        flexWrap: 'wrap',
        padding: {
          xy: pixelsToUnit(15),
        },
        [minMediumMediaQuery]: {
          margin: {
            x: 'auto',
          },
          padding: {
            xy: pixelsToUnit(30),
          },
        },
      }
    : {
        marginBottom: pixelsToUnit(40),
        [minMediumMediaQuery]: {
          padding: {
            xy: pixelsToUnit(25),
          },
        },
      };

  return props.magazines && props.magazines.length > 0 ? (
    <LatestMagazines css={layoutCss} className={BOX_ITEM_CLASSNAME}>
      <Top>
        <Heading css={{ color: props.isHorizontalCards ? black : darkerGray }}>{props.title}</Heading>
        {!!props.linkUrl && (
          <LinkContainer>
            <LinkStyled to={magazineUrl}>
              {!!props.linkText ? props.linkText : translate('/ProductListing/ShowMore')}
            </LinkStyled>
          </LinkContainer>
        )}
      </Top>
      {props.isHorizontalCards || currentBreakpoint <= Breakpoint.Micro ? (
        <Vertical items={props.magazines} />
      ) : (
        <Horizontal items={props.magazines} currentBreakpoint={currentBreakpoint} />
      )}
    </LatestMagazines>
  ) : null;
};

export default styled(LatestMagazineBlock);

const LatestMagazines = styled.section({
  backgroundColor: whitelilac,
  marginTop: pixelsToUnit(25),
  [minLargeMediaQuery]: {
    marginTop: pixelsToUnit(40),
  },
});

const Heading = styled.h2({
  color: lightBlack,
  fontSize: delta,
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(1.9),
  lineHeight: 'normal',
  padding: { xy: small },
  textAlign: 'center',
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: beta,
    fontWeight: 'normal',
    letterSpacing: pixelsToUnit(3),
    padding: { xy: huge },
  },
});

const LinkStyled = styled(Link, {
  color: (theme) => theme.textColor,
  height: pixelsToUnit(20),
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    maxWidth: '25%',
    fontSize: kappa,
  },
  ':hover': {
    borderBottom: {
      width: thin,
      color: (theme) => theme.textColor,
      style: 'solid',
    },
  },
});

const Top = styled.div({
  width: '100%',
});
const LinkContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: pixelsToUnit(5),
  [minMediumMediaQuery]: {
    justifyContent: 'flex-end',
    paddingRight: pixelsToUnit(35),
    marginBottom: pixelsToUnit(15),
  },
});
