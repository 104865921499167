import React from 'react';
import { isIE, isEdge } from '@avensia/scope';
import { styled } from '@glitz/react';
import { Style } from '@glitz/type';
import Link from 'Shared/Link';
import { lightBlack } from 'Shared/Style/colors';
import Image, { Ratio as ImageRatio, Preset as ImagePreset } from 'Shared/Image/Ratio';
import {
  pixelsToUnit,
  minMediumMediaQuery,
  minTinyMediaQuery,
  minLargeMediaQuery,
  minSmallMediaQuery,
  medium,
  zeta,
  theta,
} from 'Shared/Style';
import MagazinePageViewModel from 'Magazine/MagazinePageViewModel.type';

type PropType = {
  items: MagazinePageViewModel[];
  currentBreakpoint: number;
};

export default (props: PropType) => {
  const IEStyle: Style = {
    width: '100%',
    [minMediumMediaQuery]: {
      position: 'absolute',
      height: '100%',
      maxWidth: 'none',
    },
    [minLargeMediaQuery]: {
      width: 'inherit',
    },
  };

  const normalStyle: Style = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const imageCss = isIE() || isEdge() ? IEStyle : normalStyle;

  return props.items.length > 0 ? (
    <MagazinesWrapper>
      {props.items.map((item) => (
        <Magazine key={item.title} css={blockStyle} className="box-item-card">
          <BorderedDiv>
            <Body to={item.url}>
              <Image
                alt={item.title}
                preset={!item.imageUrl.url.includes('.gif') ? ImagePreset.Tiny : undefined}
                ratio={ImageRatio.OneToOne}
                src={item.imageUrl.url}
                imageStyle={imageCss}
              />
              <Title>{item.title}</Title>
              <Description>{item.preamble}</Description>
            </Body>
          </BorderedDiv>
          <GradientBar />
        </Magazine>
      ))}
    </MagazinesWrapper>
  ) : null;
};

const MagazinesWrapper = styled.div({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
  [minMediumMediaQuery]: {
    justifyContent: 'space-around',
  },
});

const blockStyle = {
  width: '50%',
  [minTinyMediaQuery]: {
    width: '33%',
  },
  [minMediumMediaQuery]: {
    width: '16%',
  },
};

const BorderedDiv = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const Magazine = styled.div({
  height: '350px',
  position: 'relative',
  overflow: 'hidden',
  marginTop: pixelsToUnit(15),
  padding: { x: pixelsToUnit(5), top: pixelsToUnit(5), bottom: pixelsToUnit(10) },
  [minTinyMediaQuery]: {
    height: '350px',
  },
  [minSmallMediaQuery]: {
    height: '400px',
  },
  [minMediumMediaQuery]: {
    height: '360px',
  },
  [minLargeMediaQuery]: {
    height: '450px',
  },
});

const Body = styled(Link, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingBottom: 0,
  textDecoration: 'inherit',
  color: 'inherit',
  textAlign: 'left',
});

const Title = styled.div({
  marginTop: 0,
  color: lightBlack,
  fontSize: theta,
  fontWeight: 'bold',
  [minMediumMediaQuery]: {
    fontWeight: 'normal',
    marginTop: medium,
    marginBottom: pixelsToUnit(8),
  },
});

const Description = styled.div({
  color: lightBlack,
  fontSize: zeta,
});

const GradientBar = styled.div({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '30px',
  backgroundImage: `linear-gradient(180deg, rgba(255,0,0,0), rgba(255,255,255,1))`,
});
