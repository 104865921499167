import React from 'react';
import { isIE, isEdge } from '@avensia/scope';
import { styled } from '@glitz/react';
import { Style } from '@glitz/type';
import { lightBlack } from 'Shared/Style/colors';
import Link from 'Shared/Link';
import Image, { Preset as ImagePreset } from 'Shared/Image';
import {
  pixelsToUnit,
  minMediumMediaQuery,
  minLargeMediaQuery,
  whitelilac,
  minTinyMediaQuery,
  minSmallMediaQuery,
  zeta,
  theta,
} from 'Shared/Style';
import { medium, huge } from 'Shared/Style/spacing';
import MagazinePageViewModel from 'Magazine/MagazinePageViewModel.type';

type PropType = {
  items: MagazinePageViewModel[];
};

export default (props: PropType) => {
  const IEStyle: Style = {
    width: '100%',
    [minMediumMediaQuery]: {
      position: 'absolute',
      height: '100%',
      maxWidth: 'none',
    },
    [minLargeMediaQuery]: {
      width: 'auto',
    },
  };

  const normalStyle: Style = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const imageCss = isIE() || isEdge() ? IEStyle : normalStyle;
  return props.items.length > 0 ? (
    <ListContainer>
      {props.items.map((item) => {
        return (
          <Magazine key={item.title}>
            <Body to={item.url}>
              <ImageWrapper css={(isIE() || isEdge()) && { position: 'relative' }}>
                <Image
                  css={imageCss}
                  alt={item.title}
                  preset={!item.imageUrl.url.includes('.gif') ? ImagePreset.Tiny : undefined}
                  src={item.imageUrl.url}
                />
              </ImageWrapper>
              <Details>
                <Title>{item.title}</Title>
                <Description>{item.preamble}</Description>
              </Details>
            </Body>
            <GradientBar />
          </Magazine>
        );
      })}
    </ListContainer>
  ) : null;
};

const ListContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  [minMediumMediaQuery]: {
    justifyContent: 'space-around',
    x: huge,
  },
});

const Body = styled(Link, {
  padding: { xy: pixelsToUnit(10) },
  width: '300px',
  paddingBottom: 0,
  textDecoration: 'inherit',
  color: 'inherit',
  textAlign: 'center',
});
const Magazine = styled.div({
  position: 'relative',
  padding: { xy: pixelsToUnit(5) },
  width: '100%',
  height: '350px',
  marginBottom: pixelsToUnit(15),
  overflow: 'hidden',
  WebkitLineClamp: 5,
  [minTinyMediaQuery]: {
    width: '200px',
    height: '250px',
  },
  [minSmallMediaQuery]: {
    width: '350px',
    height: '350px',
  },
  [minMediumMediaQuery]: {
    width: '300px',
    height: '360px',
  },
  [minLargeMediaQuery]: {
    width: '450px',
    height: '450px',
  },
});
const ImageWrapper = styled.div({
  flexGrow: 0,
  flexBasis: pixelsToUnit(220),
  overflow: 'hidden',
});

const Details = styled.div({
  position: 'relative',
  flexGrow: 6,
  alignItems: 'flex-start',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  [minTinyMediaQuery]: {
    maxWidth: 'initial',
  },
  paddingBottom: 0,
  textDecoration: 'inherit',
  color: 'inherit',
  textAlign: 'left',
});

const Title = styled.div({
  marginBottom: pixelsToUnit(8),
  fontSize: theta,
  marginTop: 0,
  color: lightBlack,
  fontWeight: 'bold',
  wordBreak: 'break-word',
  [minMediumMediaQuery]: {
    marginTop: medium,
  },
});

const Description = styled.div({
  backgroundColor: whitelilac,
  color: lightBlack,
  fontSize: zeta,
  [minMediumMediaQuery]: {
    marginTop: pixelsToUnit(8),
  },
});

const GradientBar = styled.div({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '30px',
  backgroundImage: `linear-gradient(180deg, rgba(255,0,0,0), rgba(255,255,255,1))`,
});
